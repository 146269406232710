import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeMainComponent } from './Pages/home/home-main/home-main.component';
import { ResultsPageComponent } from './Pages/results-page/results-page.component';
import { StreamCourseComponent } from './Pages/stream-course/stream-course.component';
import { StreamSectionComponent } from './Pages/stream-section/stream-section.component';
import { StreamTopicComponent } from './Pages/stream-topic/stream-topic.component';
import { AnimationFrameScheduler } from 'rxjs/internal/scheduler/AnimationFrameScheduler';
import { HomeCategoryComponent } from "./Pages/home/home-category/home-category.component";
import { TimerComponent } from './Parts/timer/timer.component';

const routes: Routes = [
  { path: '', component: HomeMainComponent },
  { path: 'home', component: HomeCategoryComponent },
  { path: 'stream/:id', component: StreamSectionComponent },
  { path: 'streamtopic/:id/:type', component: StreamTopicComponent },
  { path: 'course/:id', component: StreamCourseComponent },
  { path: 'results', component: ResultsPageComponent },
  { path: 'timer', component: TimerComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true ,
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
