<div class="height100 container-fluid">
  <div class="paddingX">
    <div class=" col-12 paddingTop">
      <span class="row sm-center">Mathematics > Trigonometry</span>
    </div>
    <div class="cardy">
      <div class=" row">
        <div class="col-md-7 cardy-body">
          <h1 class="">Trigonometry</h1>
          <p class="lineHeight">publishing and graphic design, Lorem ipsum is a
            placeholder text commonly used to demonstrate the
            visual form of a document or a typeface without relying
            on meaningful content. Lorem ipsum ma publishing
            and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a
            document or a typeface without relying on meaningful
            content. Lorem ipsum ma publishing and graphic
            design, Lorem ipsum is a placeholder text commonly
            used to demonstrate the visual form of a document or...
          </p>
          <span class="mb-0" style="color: #707070;">You will be given 4 set of tests</span>
        </div>
        <div class="col-md-5">
          <img class="streamCard" src="../../../assets/images/section/banner1.jpg" alt="Card image">
        </div>
      </div>
    </div>
    <div class="m-auto text-center py-4">
      <button type="button" class="btn heroHeaderButton">Jump to Test one</button>
    </div>

    <div *ngIf="spinner" class="d-flex justify-content-center">
    <span  class="spinner-border spinner-border-lg mr-2 mt-5" role="status" aria-hidden="true"></span>
  </div>
    <div class="py-5">

      <div class="row testCard" *ngFor="let test of testList" (click)="onClickButton(test.id)">
        <div class="col-md-3">
          <span class="fontSize21 font-weight-bold ">{{test.name}}</span>
        </div>
        <div class="col-md-3">
          <button type="button" class="btn Button "
          [ngClass]="{'greenColor' : test.type == 'Easy', 'orangeColor' : test.type == 'Medium', 'redColor' : test.type == 'Hard'}" >
          {{test.type}}
        </button>
        </div>
        <div class="col-md-4">
          <span class="fontSize21 ">{{test.Questions}} Questions</span>
        </div>
        <div class="col-md-2">
          <span class="" style="color: #707070;">{{test.time}} min</span>
        </div>
      </div>

    </div>
  </div>

  <app-footer></app-footer>