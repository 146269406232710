import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { CategoryService } from "../../Service/category-service.service";

@Component({
  selector: 'app-stream-topic',
  templateUrl: './stream-topic.component.html',
  styleUrls: ['./stream-topic.component.scss']
})
export class StreamTopicComponent implements OnInit {

  testList;
  spinner = false;

  constructor(private route: ActivatedRoute,private router: Router,private categoryService:CategoryService) { }

  ngOnInit(): void {
    let type = this.route.snapshot.paramMap.get('type')
    let id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.spinner = true;
    if(type=='main'){
      this.categoryService.getQuizByMainCategoryId(id).subscribe(
        res => {
          this.testList = res.body;
          console.log(res.body)
          this.spinner = false;
        }
      )
    }
    else{
      this.categoryService.getQuizBySubCategoryId(id).subscribe(
        res => {
          this.testList = res.body;
          //console.log(res.body)
        }
      )
    }
    
  }

  onClickButton(id){
    this.router.navigate(['/course', id])
    //console.log(id);
  }

}
