
<div class="height100 container-fluid">
    <div class="header-bg " id="circle" ></div>
    <div class="row">
        <div class="col-md-7 headerImg">
            <img src="../../../../assets/images/headerImage.png" alt="Landing page Image" class="img-fluid"> 
        </div>
        <div class="col-md-5 align-self-center">

            <!-- social login  -->
            <!-- <ng-container *ngIf="getStartedRegister; else registerCompononet"></ng-container> -->
            <!-- <div  *ngIf="getStartedRegister; else registerCompononet" [hidden]="!getStartedLogin"> -->
            <div  *ngIf="getStartedRegister; else registerCompononet" [hidden]="!getStartedLogin">
                <div class="col-lg-10 m-auto pt-4">
                    <p class="primaryColorDark" (click)="register()"><span>&#10094;</span> Back</p>
                </div>
                
                <div class="text-center">
                    <h1>Get Started</h1>
                    <h5>Get the best practices for your exams</h5>
                </div>
                <form class="col-lg-10 m-auto pt-5" [formGroup]="registerForm" (ngSubmit)="onSubmit()" id="javascript_form">

                    <div class="form-group">
                        <input type="text" name="email"  id="email" formControlName="email" class="form-control input" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="password" name="password"  id="password" formControlName="password" class="form-control input" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Password" />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                            <div *ngIf="f.password.errors.minlength">Minimum 8 letters required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="password" name="confirmPassword"  id="confirmPassword" formControlName="confirmPassword" class="form-control input" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" placeholder="Confirm Password" />
                        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                            <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                            <div *ngIf="f.confirmPassword.errors.confirmedValidator">Password must be match</div>
                        </div>
                    </div>
                  
                   <!-- <div id="SocialButton">
                    <div class="input-group bg-primaryColorDark">                   
                        <input type="submit" class="form-control" value="Register" #photoInput> 
                    </div>
                   </div>   -->
                   <div id="SocialButton">
                    <div *ngIf="errorMessage != null" class="alert alert-danger" role="alert">
                      {{errorMessage}}
                    </div>
                    <div class="input-group bg-primaryColorDark">
                      <button type="submit" class="form-control" value="Login">
                        <ng-container *ngIf="spinner; then thenTemplate; else elseTemplate"></ng-container>
                        <ng-template #thenTemplate>
                          <span *ngIf="spinner" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        </ng-template>
                        <ng-template #elseTemplate>
                            Register
                        </ng-template>
                      </button>
                    </div>
                   </div>                   
                </form>
                <div>
                    <p class="text-center">Already have an account? <span class="primaryColorDark"  (click)="login()">Login</span></p>
                </div>   
            </div>

             <!-- register  -->
            <ng-template #registerCompononet >
                <div [hidden]="getStartedRegister || getStartedRegister === false && getStartedLogin === false">
                    <div class="text-center pt-3">
                        <h1>Get Started</h1>
                        <h5>Get the best practices for your exams</h5>
                    </div>
                    <div class="col-lg-10 m-auto pt-4" id="SocialButton">
                        <div class="input-group bg-lightBlue" (click)="signInGoogle()">                   
                            <img src="../../../../assets/images/google.png" alt="Landing page Image" class="img-fluid">
                            <input type="button" class="form-control" value="Sign in with Google">
                        </div>
                        <div class="input-group bg-darkBlue" (click)="signInWithFB()">
                            <img src="../../../../assets/images/facebook.png" alt="Landing page Image" class="img-fluid"> 
                            <input type="button" class="form-control" value="Sign in with Facebook">
                        </div>   
                        <div class="input-group bg-border" (click)="register()">
                            <img src="../../../../assets/images/mail.png" alt="Landing page Image" class="img-fluidBorder "> 
                            <input type="button" class="form-control" style="color: #1E8FFF;" value="Continue with Email">
                        </div>   
                        <div>
                            <p class="text-center">Already have an account? <span class="primaryColorDark"  (click)="login()">Login</span></p>
                        </div>     
                    </div>
                </div>

                
            </ng-template>


            <ng-container *ngIf="getStartedLogin; else loginComponent"></ng-container>
            <ng-template #loginComponent>
                <div class="col-lg-10 m-auto pt-4">
                    <p class="primaryColorDark" (click)="login()"><span>&#10094;</span> Back</p>
                </div>
                
                <div class="text-center">
                    <h1>Get Started</h1>
                    <h5>Get the best practices for your exams</h5>
                </div>
                <form class="col-lg-10 m-auto pt-5" [formGroup]="loginForm" (ngSubmit)="onSubmitlogin()">

                    <div class="form-group">
                        <input type="text" name="email"  id="email" formControlName="email1" class="form-control input" [ngClass]="{ 'is-invalid': submittedLogin && l.email1.errors }" placeholder="Email" />
                        <div *ngIf="submittedLogin && l.email1.errors" class="invalid-feedback">
                            <div *ngIf="l.email1.errors.required">Email is required</div>
                            <div *ngIf="l.email1.errors.email1">Email must be a valid email address</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="password" name="password1"  id="password1" formControlName="password1" class="form-control input" [ngClass]="{ 'is-invalid': submittedLogin && l.password1.errors }" placeholder="Password" />
                        <div *ngIf="submittedLogin && l.password1.errors" class="invalid-feedback">
                            <div *ngIf="l.password1.errors.required">Password is required</div>
                        </div>
                    </div>
                  
                   <div id="SocialButton">
                    <div *ngIf="errorMessage != null" class="alert alert-danger" role="alert">
                      {{errorMessage}}
                    </div>
                    <div class="input-group bg-primaryColorDark">
                      <button type="submit" class="form-control" value="Login">
                        <ng-container *ngIf="spinner; then thenTemplate; else elseTemplate"></ng-container>
                        <ng-template #thenTemplate>
                          <span *ngIf="spinner" class="spinner-border spinner-border-sm mr-2" role="status"
                            aria-hidden="true"></span>
                        </ng-template>
                        <ng-template #elseTemplate>
                          Login
                        </ng-template>
                      </button>
                    </div>
                   </div>                    
                </form>
                <div>
                    <p [hidden]="getStartedRegister === false && getStartedLogin === false" class="text-center">Don’t have an account? <span class="primaryColorDark"  (click)="login()">Register</span></p>
                    <p [hidden]="getStartedRegister === true && getStartedLogin === false" class="text-center">Don’t have an account? <span class="primaryColorDark"  (click)="registerSub()">Register</span></p>
                </div> 

            </ng-template>
            

        </div>
    </div>
    
</div>