import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-it-goes',
  templateUrl: './how-it-goes.component.html',
  styleUrls: ['./how-it-goes.component.scss']
})
export class HowItGoesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
