<p>timer works!</p>


<section>
	<div class="rt-container">
		<div class="col-rt-12">
			<div class="Scriptcontent">

				<div class="page">
					<div class="circles-container">
						<div id="circle-1" data-circle-dialWidth=10 data-circle-skin="yellow">
							<div class="loader-bg">
								<div class="text">00:00:00</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</section>
<script src='https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.min.js'></script>
<script src='../../../assets/circlebar.js'></script>
<script>
    
     $(document).ready(function() {
	$("#circle-1").Circlebar({
		maxValue: 8,
		fontSize: "14px",
		triggerPercentage: true
	});
});
</script>