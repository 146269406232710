import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  
  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  getSubCategoryByMainCategoryId(apiData){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(this.baseUrl + 'getSubCategoryByMainCategoryId/'+apiData, {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  getMainCategoryById(apiData){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(this.baseUrl + 'getMainCategoryById/'+apiData, {

      headers: httpHeaders,
      observe: 'response'
    });
  }


  getQuizBySubCategoryId(apiData){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(this.baseUrl + 'getQuizBySubCategoryId/'+apiData, {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  getQuizByMainCategoryId(apiData){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(this.baseUrl + 'getQuizByMainCategoryId/'+apiData, {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  getQuestionsByQuizId(apiData) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    console.log(apiData)
    return this.http.get(this.baseUrl + 'getQuestionsByQuizId/'+apiData, {

      headers: httpHeaders,
      observe: 'response'
    });
  }

  
}
