import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // $(document).ready(function() {
    //   $("#circle-1").Circlebar({
    //     maxValue: 8,
    //     fontSize: "14px",
    //     triggerPercentage: true
    //   });
    // });
  }

}
