import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Hero } from './category';
import { HEROES } from './mock-category';
import { CategoryService } from "../../Service/category-service.service";

@Component({
  selector: 'app-stream-section',
  templateUrl: './stream-section.component.html',
  styleUrls: ['./stream-section.component.scss']
})
export class StreamSectionComponent implements OnInit {
  streamSecHeading = 'test';
  streamSecContent = 'publishing and graphic design, Lorem ipsum is a placeholder text publishing and graphic design, Lorem ipsum is a placeholder text';
  streamSecImg = '../../../assets/images/section/banner1.jpg';
  streamSecImg1 = '../../../assets/images/section/banner2.jpg';
  streamSecDuration = '8 mins';

  heroes = HEROES;
  selectedHero?: Hero;
  categoryId;
  streamCardContent;

  constructor(private route: ActivatedRoute,private router: Router,private categoryService:CategoryService) { }

  ngOnInit(): void {
    let id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.categoryService.getSubCategoryByMainCategoryId(id).subscribe(
      res => {
        this.streamCardContent = res.body;
        console.log(res.body)
      }
    )
    //console.log("test", id);
    this.categoryId = id;
    console.log(this.streamCardContent);
  }

  onSelect(hero: Hero): void {
    this.selectedHero = hero;
  }
  onClickButton(id){
    this.router.navigate(['/streamtopic', id,'sub'])
    console.log(id)
  }

}
