import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-stream-section-card',
  templateUrl: './stream-section-card.component.html',
  styleUrls: ['./stream-section-card.component.scss']
})
export class StreamSectionCardComponent implements OnInit {
  @Input() streamSecHeading: string;
  @Input() streamSecContent: string;
  @Input() streamSecDuration: string;
  @Input() streamSecImg: string;
  @Output() onClick = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  onClickButton(event) {
    this.onClick.emit(event + "testt");
  }
}
