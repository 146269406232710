 <!-- Footer -->
 <footer class="">
    <div class="container py-3">
        <div class="row col pl-4 pt-4">
            <div class="pl-3">
                <img src="../../../../assets/svg/sampleLogo.svg" alt="Logo" class="img-fluid">
            </div>
            <div class="col socialIcon">
                <a href="">
                    <img src="../../../../assets/svg/google.svg" alt="Logo" class="img-fluid">
                </a>
                <a href="">
                    <img src="../../../../assets/svg/twitter.svg" alt="Logo" class="img-fluid pl-lg-3 pl-md-3 pl-2">
                </a>
                <a href="">
                    <img src="../../../../assets/svg/facebook.svg" alt="Logo" class="img-fluid pl-lg-3 pl-md-3 pl-2">
                </a>                
            </div>
        </div>
        
      <div class="row py-4">
        <div class="col-lg-3 col-md-6 mb-4">
            <ul class="">
              <h6 class="text-uppercase font-weight-bold mb-4">First</h6>
              <li class="mb-2"><a href="#" class="">First page</a></li>
              <li class="mb-2"><a href="#" class="">Second page</a></li>
              <li class="mb-2"><a href="#" class="">Third page</a></li>
              <li class="mb-2"><a href="#" class="">Fourth page</a></li>
            </ul>
        </div>
        <div class="col-lg-3 col-md-6 mb-4">          
          <ul class="">
            <h6 class="text-uppercase font-weight-bold mb-4">Second</h6>
            <li class="mb-2"><a href="#" class="">First page</a></li>
            <li class="mb-2"><a href="#" class="">Second page</a></li>
            <li class="mb-2"><a href="#" class="">Third page</a></li>
            <li class="mb-2"><a href="#" class="">Fourth page</a></li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-6 mb-4">          
          <ul class="">
            <h6 class="text-uppercase font-weight-bold mb-4">Third</h6>
            <li class="mb-2"><a href="#" class="">First page</a></li>
            <li class="mb-2"><a href="#" class="">Second page</a></li>
            <li class="mb-2"><a href="#" class="">Third page</a></li>
            <li class="mb-2"><a href="#" class="">Fourth page</a></li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-6 mb-4">            
            <ul class="">
                <h6 class="text-uppercase font-weight-bold mb-4">Fourth</h6>
                <li class="mb-2"><a href="#" class="">First page</a></li>
                <li class="mb-2"><a href="#" class="">Second page</a></li>
                <li class="mb-2"><a href="#" class="">Third page</a></li>
                <li class="mb-2"><a href="#" class="">Fourth page</a></li>
              </ul>
          </div>
      </div>
      <div class="">
        <div class="row text-center m-auto col-8" style="justify-content: space-evenly;">
          <a href="#" class=" mb-0 py-2">© Copyrights 2021</a>
          <a href="#" class=" mb-0 py-2">Privacy policy</a>
          <a href="#" class=" mb-0 py-2">Terms of service</a>
        </div>
      </div>

    </div>
  </footer>
  <!-- End -->

  