import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, MinLengthValidator, Validators } from '@angular/forms';
import { ConfirmedValidator } from './must-match.validator';
import { AuthService } from "../../../Service/auth.service";
import { SocialAuthService,SocialUser,GoogleLoginProvider,FacebookLoginProvider } from "angularx-social-login";
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  getStartedRegister: boolean = false
  getStartedLogin: boolean = true
  registerForm!: FormGroup;
  loginForm!: FormGroup;
  submitted = false;
  submittedLogin = false;
  spinner = false;
  user: SocialUser;

  errorMessage = null;

  constructor(private formBuilder: FormBuilder,private authService:AuthService,private router:Router,private socialAuthService:SocialAuthService) { }

  register(){
    this.getStartedRegister = !this.getStartedRegister;
    // console.log(`getStartedRegister`,this.getStartedRegister);
  }
  registerSub(){
    this.getStartedRegister = !this.getStartedRegister;
    this.getStartedLogin = !this.getStartedLogin;
  }
  login(){
    this.getStartedLogin = !this.getStartedLogin
    // console.log(`getStartedLogin`,this.getStartedLogin)
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required, ]
    },{
      validator: ConfirmedValidator('password', 'confirmPassword')
    });
    this.loginForm = this.formBuilder.group({
      email1: ['', [Validators.required, Validators.email]],
      password1: ['', Validators.required],
    });
    this.socialAuthService.authState.subscribe((user) => {
      this.user = user;
      //console.log(user);
    })
    
  }

  signInGoogle(){
    const res = this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID)
    res.then(
      ans => {
        console.log(ans)
        if(ans.email != null){
          this.router.navigateByUrl('/home')
        }
        //console.log(ans.email)
      }
    )
  }

  signInWithFB() {
    const res = this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
    res.then(
      ans => {
        if(ans.email != null){
          this.router.navigateByUrl('/home')
        }
      }
    )
  }

  get f() { return this.registerForm.controls; }
  get l() { return this.loginForm.controls; }

  onSubmit() {
      this.submitted = true;
      this.spinner = true;
      
      // stop here if form is invalid
      if (this.registerForm.invalid) {
          this.spinner = false;
          return;
      }
      // display form values on success
      this.authService.SignUp({
        "email" : this.registerForm.value.email,
        "password": this.registerForm.value.password,
        "name" : "jinthu"
      }).subscribe(
        res => {
          // alert(res.body['message'])
          this.errorMessage = res.body['message'];
          console.log(this.errorMessage);
          this.spinner = false;
        }
      )
      
      //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
      this.submitted = false;
      // this.registerForm.reset();
  }
  onSubmitlogin() {
    this.submittedLogin = true;
    this.spinner = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
        this.spinner = false;
        return;
    }


    this.authService.LogIn({
      "email" : this.loginForm.value.email1,
      "password": this.loginForm.value.password1
    }).subscribe(
      res => {
        if(res.body['token'] != null){
          this.router.navigateByUrl('/home')
          this.spinner = false;
        } else {
          this.errorMessage = res.body['message'];
          console.log(this.errorMessage);
          this.spinner = false;
        }
        
      }
    )
    
    // display form values on success
    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.loginForm.value, null, 4));
    this.submittedLogin = false;
    // this.spinner = false;
    // this.loginForm.reset();
}

  onReset() {
      this.submitted = false;
      this.registerForm.reset();
  }


}
