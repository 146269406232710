<div class="height100 container-fluid">
    <div class="section-bg" id="circle" ></div>
    <div class="position-relative text-center paddingTop">
        <h1 class="text-white">We offer more than anybody</h1>
        <a>
            <button type="button" class="btn heroHeaderButton">Start now</button>
        </a>
    </div>
</div>

<div class="" style="background-color: #F1F5F9" >
    <div class="container">
        <div class="text-center py-5">
            <span class="miniHeader">Best practice {{categoryId}}</span>
            <h1>You can start your practice</h1>
        </div>
        <div class=" col-12 ">
            <p class="row sm-center">Choose the Stream</p>

            <div class="row sm-center" >
                <div class="d-inline-block pb-2 pr-2 heroes" 
                    *ngFor="let hero of heroes"
                    (click)="onSelect(hero)">
                    <button type="button" [class.selected]="hero === selectedHero" class="btn">{{hero.name}}</button>                    
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6"  *ngFor="let hero of streamCardContent">
                  <app-stream-section-card 
                  (click)="onClickButton(hero.id)"
                  streamSecHeading="{{hero.name}} {{hero.id}}" 
                  streamSecContent={{hero.description}} 
                  streamSecDuration={{hero.duration}}
                  streamSecImg={{streamSecImg}}>
                  </app-stream-section-card>
                </div>

            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>