<div class="height100 container">
    <div class="paddingX">
      <!-- <div class=" col-12 paddingTop">
        <span class="row sm-center">Mathematics > Trigonometry > Test One</span>
      </div> -->
      <div class="my-5">
        <img src="../../../assets/images/results.png" alt="results image" class="img-fluid mr-2">
        <h1 class="mt-4">Great Work, Joseph.</h1>
        <div class="col-md-6 m-auto">
            <h5>You have answered {{correctAnswer}} questions correctly out of {{totalQuestion}} questions</h5>
        </div>
      </div>
      <div class="col-md-9 m-auto">
        <div class="row marginAuto">
            <div class="square {{ isCorrect(question) == 'correct'? 'greenColor': 'redColor'}}" *ngFor="let question of quiz.questions; let index = index" (click)="showRadio(question)">
              {{index + 1}}
            </div>
          </div>
      </div>
      <div class="row mt-4 mb-5 col-4 resultBtn">
        <button routerLink="/home" type="button" class="btn heroHeaderButton">Back to home</button>
      </div>
    </div>
</div>