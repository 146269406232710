import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeMainComponent } from './Pages/home/home-main/home-main.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CategoryComponent } from './Pages/home/category/category.component';
import { HeaderComponent } from './Pages/home/header/header.component';
import { HowItGoesComponent } from './Pages/home/how-it-goes/how-it-goes.component';
import { ButtonComponent } from './Parts/button/button.component';
import { FooterComponent } from './Pages/footer/footer.component';

import { HttpClientModule } from '@angular/common/http';

import { StreamSectionComponent } from './Pages/stream-section/stream-section.component';
import { StreamSectionCardComponent } from './Parts/stream-section-card/stream-section-card.component';
import { StreamTopicComponent } from './Pages/stream-topic/stream-topic.component';
import { StreamCourseComponent } from './Pages/stream-course/stream-course.component';
import { ResultsPageComponent } from './Pages/results-page/results-page.component';
import { HomeCategoryComponent } from './Pages/home/home-category/home-category.component';
import { SocialAuthServiceConfig,SocialLoginModule,GoogleLoginProvider, SocialAuthService,FacebookLoginProvider } from "angularx-social-login";
import { TimerComponent } from './Parts/timer/timer.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeMainComponent,
    CategoryComponent,
    HeaderComponent,
    HowItGoesComponent,
    ButtonComponent,
    FooterComponent,
    StreamSectionComponent,
    StreamSectionCardComponent,
    StreamTopicComponent,
    StreamCourseComponent,
    ResultsPageComponent,
    HomeCategoryComponent,
    TimerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SocialLoginModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '2288200448-uv6c89sng360brri3queklho3tmihc3r.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('911749842889076')
          }
        ]
      } as SocialAuthServiceConfig,
    }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
