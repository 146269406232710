<div class="container-fluid pb-5">
    <div class="text-center my-5">
        <span class="miniHeader">SIMPLE AND FUN</span>
        <h1>How It Goes</h1>
        <h5>We make you feel achievable</h5>
    </div>
    <div class="row">
        <div class="col-lg-4" style="align-self: center;justify-content: center;display: flex;">
            <img src="../../../../assets/images/howItGoes.png" alt="Landing page Image" class="img-fluid"> 
        </div>
        <div class="col-lg-8 mt-lg-0 mt-md-5 mt-sm-5">
            <div class="row">
                <div class="col-lg-6 col-md-6 boxPadding row">
                    <div class="col-3 mb-auto">
                        <img src="../../../../assets/svg/quiz.svg" alt="Landing page Image" class="img-fluid howIcons"> 
                    </div>
                    <div class="col">
                        <h5>Do the Quiz</h5>
                        <p class="howItGoesParah">An empirically estimated uncanny valley for static robot face images In aesthetics. An empirically estimated uncanny valley for static robot face images In aesthetics.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 boxPadding row">
                    <div class="col-3 mb-auto text-center">
                        <img src="../../../../assets/svg/realise.svg" alt="Landing page Image" class="img-fluid howIcons"> 
                    </div>
                    <div class="col">
                        <h5>Realise where you are</h5>
                        <p class="howItGoesParah">An empirically estimated uncanny valley for static robot face images In aesthetics. An empirically estimated uncanny valley for static robot face images In aesthetics.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 boxPadding row">
                    <div class="col-3 mb-auto text-center">
                        <img src="../../../../assets/svg/reflect.svg" alt="Landing page Image" class="img-fluid howIcons"> 
                    </div>
                    <div class="col">
                        <h5>Reflect on results</h5>
                        <p class="howItGoesParah">An empirically estimated uncanny valley for static robot face images In aesthetics. An empirically estimated uncanny valley for static robot face images In aesthetics.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 boxPadding row">
                    <div class="col-3 mb-auto">
                        <img src="../../../../assets/svg/practice.svg" alt="Landing page Image" class="img-fluid howIcons"> 
                    </div>
                    <div class="col">
                        <h5>Keep practicing</h5>
                        <p class="howItGoesParah">An empirically estimated uncanny valley for static robot face images In aesthetics. An empirically estimated uncanny valley for static robot face images In aesthetics.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">

            </div>
        </div>
    </div>
</div>