import { Component, OnInit } from '@angular/core';
import { Option, Question, Quiz, QuizConfig } from 'src/app/models';
import { QuizService } from 'src/app/services/quiz.service';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from "../../Service/category-service.service";


@Component({
  selector: 'app-stream-course',
  templateUrl: './stream-course.component.html',
  styleUrls: ['./stream-course.component.scss'],
  providers: [QuizService]
})
export class StreamCourseComponent implements OnInit {
  numbers;
  answers = [
    {id: 1, title: 'Answer one'},
    {id: 2, title: 'Answer two'},
    {id: 3, title: 'Answer three'},
    {id: 4, title: 'Answer four'},
    {id: 5, title: 'Answer five'},
    {id: 6, title: 'Answer six'},
  ];
  Message = "Parent to Child"

  // new
  quizes: any[];
  quiz: Quiz = new Quiz(null);
  mode = 'quiz';
  quizName: string;
  config: QuizConfig = {
    'allowBack': true,
    'allowReview': true,
    'autoMove': false,  // if true, it will move to next question automatically when answered.
    'duration': 300,  // indicates the time (in secs) in which quiz needs to be completed. 0 means unlimited.
    'pageSize': 1,
    'requiredAll': false,  // indicates if you must answer all the questions before submitting.
    'richText': false,
    'shuffleQuestions': false,
    'shuffleOptions': false,
    'showClock': false,
    'showPager': true,
    'theme': 'none'
  };

  pager = {
    index: 0,
    size: 1,
    count: 1
  };
  timer: any = null;
  startTime: Date;
  endTime: Date;
  ellapsedTime = '00:00';
  duration = '';
  courseId;

  spinner = false;
  errorMessage = null;

  constructor(private quizService: QuizService,private route: ActivatedRoute,private categoryService:CategoryService) {
    // this.numbers = Array(40).fill(0).map((x,i)=>i+1);
  }

  ngOnInit(): void {
    let id = parseInt(this.route.snapshot.paramMap.get('id'));
    //console.log(id)
    this.spinner = true;
    this.categoryService.getQuestionsByQuizId(id).subscribe(res => {
      console.log(res.body)
      this.spinner = false;
      this.quiz = new Quiz(res.body);
      this.pager.count = this.quiz.questions.length;
      this.startTime = new Date();
      this.ellapsedTime = '00:00';
      this.timer = setInterval(() => { this.tick(); }, 1000);
      this.duration = this.parseTime(this.config.duration);
    });
    this.mode = 'quiz';
    //this.quizes = this.quizService.getAll();
    //console.log(this.quizes)
    //this.quizName = this.quizes[0].id;
    //this.loadQuiz(this.quizName);
  }
  // showRadio(i){
  //   console.log(i);
  // }

  loadQuiz(quizName: string) {
    this.quizService.get(quizName).subscribe(res => {
      //console.log(res)
      this.quiz = new Quiz(res);
      this.pager.count = this.quiz.questions.length;
      this.startTime = new Date();
      this.ellapsedTime = '00:00';
      this.timer = setInterval(() => { this.tick(); }, 1000);
      this.duration = this.parseTime(this.config.duration);
    });
    this.mode = 'quiz';
  }

  tick() {
    const now = new Date();
    const diff = (now.getTime() - this.startTime.getTime()) / 1000;
    if (diff >= this.config.duration) {
      this.onSubmit();
    }
    this.ellapsedTime = this.parseTime(diff);
  }

  parseTime(totalSeconds: number) {
    let mins: string | number = Math.floor(totalSeconds / 60);
    let secs: string | number = Math.round(totalSeconds % 60);
    mins = (mins < 10 ? '0' : '') + mins;
    secs = (secs < 10 ? '0' : '') + secs;
    return `${mins}:${secs}`;
  }

  get filteredQuestions() {
    return (this.quiz.questions) ?
      this.quiz.questions.slice(this.pager.index, this.pager.index + this.pager.size) : [];
  }

  onSelect(question: Question, option: Option) {
    if (question.questionTypeId === 1) {
      question.options.forEach((x) => { if (x.id !== option.id) x.selected = false; });
    }

    if (this.config.autoMove) {
      this.goTo(this.pager.index + 1);
    }
  }

  goTo(index: number) {
    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
      this.mode = 'quiz';
    }
  }

  isAnswered(question: Question) {
    return question.options.find(x => x.selected) ? 'Answered' : 'Not Answered';
  };

  isCorrect(question: Question) {
    return question.options.every(x => x.selected === x.isAnswer) ? 'correct' : 'wrong';
  };

  onSubmit() {
    let answers = [];
    this.quiz.questions.forEach(x => answers.push({ 'quizId': this.quiz.id, 'questionId': x.id, 'answered': x.answered }));

    // Post your data to the server here. answers contains the questionId and the users' answer.
    console.log(this.quiz.questions);
    this.mode = 'result';
  }
}
