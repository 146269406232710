import { Component, Input, OnInit } from '@angular/core';
import { Question } from 'src/app/models';

@Component({
  selector: 'app-results-page',
  templateUrl: './results-page.component.html',
  styleUrls: ['./results-page.component.scss']
})
export class ResultsPageComponent implements OnInit {
  numbers;
  @Input() quiz;
   correctAnswer = 0;
   totalQuestion;
  constructor() {
    this.numbers = Array(40).fill(0).map((x,i)=>i+1);
  }

  ngOnInit(): void {
    this.totalQuestion = this.quiz.questions.length
    var xx = this.quiz.questions.map(question => 
      this.isCorrect(question)
    );
    for (let i = 0; i < xx.length; i++) {
      if(xx[i] == 'correct'){
        this.correctAnswer = this.correctAnswer + 1
      }
    }
  }

  showRadio(i){
    console.log(i);
  }

  isCorrect(question: Question) {
    return question.options.every(x => x.selected === x.isAnswer) ? 'correct' : 'wrong';
  };

}
