<div class=" container">
  <div class="paddingX">
    <div class=" col-12 paddingTop">
      <span class="row sm-center">Mathematics > Trigonometry > Test {{courseId}}</span>
    </div>
    <!-- <div class="col-6 text-right">
      Select Quiz:
      <select [(ngModel)]="quizName" (change)="loadQuiz(quizName)">
        <option *ngFor="let quiz of quizes" [value]="quiz.id">{{quiz.name}}</option>
      </select>
    </div> -->

    <!-- <div class="spinner-border" role="status"></div> -->
    <div class="d-flex justify-content-center">
    <span *ngIf="spinner" class="spinner-border spinner-border-lg mr-2 mt-5" role="status" aria-hidden="true"></span>
  </div>
    
    <div class="row reverse" *ngIf="mode=='quiz' && quiz">
      <div class="col-lg-8 col-md-8 mt-5" *ngFor="let question of filteredQuestions; first as isFirst">
        <h5 class="lightBlue">Qn: {{pager.index + 1}} of {{pager.count}}</h5>
        <p class="py-4" style="font-size: 20px;">{{question.name}} </p>
        <div class="grayBox">

          <div class="row box custom-radio" *ngFor="let option of question.options; let i = index;">
            <input id="{{option.id}}" type="checkbox" [(ngModel)]="option.selected"
              (change)="onSelect(question, option);" />
            <label [attr.for]="option.id">
              <div class="round">{{i +1}}</div>
              <span>{{option.name}}</span>
            </label>
          </div>

        </div>
        <div class=" mt-4 mb-5 row" style="justify-content: space-between;">
          <div class="col-md-8" *ngIf="mode!='result'">
            <button type="button" class="btn heroHeaderButton bg-yellow mr-3" (click)="mode = 'review'">Review</button>
            <button type="button" class="btn heroHeaderButton bg-green" (click)="onSubmit();">Submit</button>
          </div>
          <div class="col-md-4 text-md-right nextBtn" *ngIf="pager.index !== pager.count-1">
            <button type="button" class="btn heroHeaderButton bg-blue" (click)="goTo(pager.index + 1);">Next</button>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 paddingLeft">
    
        <!-- <div class="row">
          <div class="square" *ngFor="let number of numbers ;let i= index" (click)="showRadio(number)">
            {{number}}
          </div>
        </div> -->

        <div class="row">
          <div 
          class="square  {{ isAnswered(question) == 'Answered'? 'bg-blue': 'bg-grey' }}"
          *ngFor="let question of quiz.questions; let index = index;" 
          [ngStyle]="{'border':pager.index + 1 === index +1 ? '2px solid #1E8FFF' : '' }"
          (click)="goTo(index)" >
            {{index +1}}
          </div>
        </div>

        <!-- {{question}} -->
        <!-- <div class="col-4 cursor-pointer" *ngFor="let question of quiz.questions; let index = index;">
          <div (click)="goTo(index)" class="p-3 mb-2 {{ isAnswered(question) == 'Answered'? 'bg-info': 'bg-warning' }}">{{index + 1}}. {{ isAnswered(question) }}</div>
        </div> -->
        
        <div class="row ml-0 mt-2">
          <img src="../../../../assets/svg/clock.svg" alt="Clock Icon" class="img-fluid mr-2" style="width: 18px;">
          <span style="font-size: 12px;">You have 40 min</span>
          <!-- <div *ngIf="config.duration" class="badge badge-info float-right">Time: {{ellapsedTime}} / {{duration}}</div> -->
        </div>
      </div>
    </div>

    <!-- <div class="row text-center pt-5" *ngIf="mode=='review'">
      <div class="col-4 cursor-pointer" *ngFor="let question of quiz.questions; let index = index;">
        <div (click)="goTo(index)" class="p-3 mb-2 {{ isAnswered(question) == 'Answered'? 'bg-blue': 'bg-yellow' }}">{{index + 1}}. {{ isAnswered(question) }}</div>
      </div>
    </div> -->

    <div class="row pt-5" *ngIf="mode=='review'">
      <div 
      class="square  {{ isAnswered(question) == 'Answered'? 'bg-blue': 'bg-grey' }}"
      *ngFor="let question of quiz.questions; let index = index;" 
      (click)="goTo(index)" >
        {{index +1}}
      </div>
    </div>
    
    <div class="result" *ngIf="mode=='result'">
      <!-- <h2>Quiz Result</h2>
      <div *ngFor="let question of quiz.questions; let index = index">
        <div class="result-question">
          <h5>{{index + 1}}. {{question.name}}</h5>
          <div class="row">
            <div class="col-6" *ngFor="let Option of question.options">
              <input id="{{Option.id}}" type="checkbox" disabled="disabled" [(ngModel)]="Option.selected" /> {{Option.name}}
            </div>
          </div>
          <div class="p-1 m-2 alert {{ isCorrect(question) == 'correct'? 'alert-success': 'alert-danger'}}">Your answer is {{isCorrect(question)}}.</div>
        </div>
      </div>
      <h4 class="alert alert-info text-center">You may close this window now.</h4> -->
      <app-results-page [quiz]="quiz"></app-results-page>
    </div>

    <hr />
    <div class="pb-5">
      <button *ngIf="mode=='review'" type="button" class="btn heroHeaderButton bg-yellow mr-3" (click)="mode = 'quiz'">Quiz</button>
    </div>
    <!-- <div *ngIf="mode!='result'" class="pb-5">
      <button class="btn btn-warning" (click)="mode = 'quiz'">Quiz</button>
      <button class="btn btn-info" (click)="mode = 'review'">Review</button>
      <button class="btn btn-primary" (click)="onSubmit();">Submit Quiz</button>
    </div> -->

  </div>
</div>


<app-footer></app-footer>