<div class="container pb-5">
    <div class="text-center my-5">
        <span class="miniHeader">FOUR IN ONE</span>
        <h1>We offer more than anybody</h1>
        <h5>We provide practices in various fields</h5>
    </div>
    <div class="row">
        <div class="col-lg-6" id="categoryCard">
            <div class="row cardBg">
            <div class="col cardText">
                <span class="miniHeader">License Exam</span>
                <p>We provide License Exam practices.</p>
                <a (click)="onSelect('1')">
                    <button type="button" class="btn heroHeaderButton">View</button>
                </a>
            </div>
            <div class="">
                <picture>
                    <source media="(min-width: 575.98px)" srcset="../../../../assets/svg/category/licenseExam.svg">
                    <source media="(max-width: 575.97px)" srcset="../../../../assets/svg/category/licenseExamMobile.svg">
                    <img  alt="a cute kitten" class="img-fluid">
                </picture>
            </div>
            </div>
        </div>
        <div class="col-lg-6" id="categoryCard">
            <div class="row cardBg">
            <div class="col cardText">
                <span class="miniHeader">A/L Exam</span>
                <p>We provide A/L Exam practices.</p>
                <a (click)="onSelect('2')">
                    <button type="button" class="btn heroHeaderButton">View</button>
                </a>
            </div>
            <div class="">
                <picture>
                    <source media="(min-width: 575.98px)" srcset="../../../../assets/svg/category/alExam.svg">
                    <source media="(max-width: 575.97px)" srcset="../../../../assets/svg/category/alExamMobile.svg">
                    <img  alt="a cute kitten" class="img-fluid">
                </picture>
            </div>
            </div>
        </div>
        <div class="col-lg-6" id="categoryCard">
            <div class="row cardBg">
            <div class="col cardText">
                <span class="miniHeader">O/L Exam</span>
                <p>We provide O/L Exam practices.</p>
                <a (click)="onSelect('3')">
                    <button type="button" class="btn heroHeaderButton">View</button>
                </a>
            </div>
            <div class="">
                <picture>
                    <source media="(min-width: 575.98px)" srcset="../../../../assets/svg/category/olExam.svg">
                    <source media="(max-width: 575.97px)" srcset="../../../../assets/svg/category/olExamMobile.svg">
                    <img  alt="a cute kitten" class="img-fluid">
                </picture>
            </div>
            </div>
        </div>
        <div class="col-lg-6" id="categoryCard">
            <div class="row cardBg">
            <div class="col cardText">
                <span class="miniHeader">Aptitude Exam</span>
                <p>We provide Aptitude Exam practices.</p>
                <a (click)="onSelect('4')">
                    <button type="button" class="btn heroHeaderButton">View</button>
                </a>
            </div>
            <div class="">
                <picture>
                    <source media="(min-width: 575.98px)" srcset="../../../../assets/svg/category/aptitude.svg">
                    <source media="(max-width: 575.97px)" srcset="../../../../assets/svg/category/aptitudeMobile.svg">
                    <img  alt="a cute kitten" class="img-fluid">
                </picture>
            </div>
            </div>
        </div>


        
    </div>
    
    <div></div>
</div>