import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from "../../../Service/category-service.service";

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  label = 'View';
  routerLink1= "/stream"
  routerLink2= "/contact1"
  routerLink3= "/contact2"
  routerLink4= "/contact3"

  // categories = [
  //   {id : 1, title: 'title', content: 'content', button: 'View'},
  //   {id : 1, title: 'title', content: 'content', button: 'View'},
  //   {id : 1, title: 'title', content: 'content', button: 'View'},
  //   {id : 1, title: 'title', content: 'content', button: 'View'},
  // ];

  // categories = [
  //   {id : 1},
  //   {id : 2},
  //   {id : 3},
  //   {id : 4},
  // ]
  
  constructor(private router: Router,private categoryService:CategoryService) { }

  ngOnInit(): void {
    // this.categories;
  }

  onSelect(categories){

    this.categoryService.getMainCategoryById(categories).subscribe(
      res => {
        if(res.body['MoreCategory'] == 0){
          this.router.navigate(['/streamtopic',categories,'main'])
        }
        else{
          this.router.navigate(['/stream', categories])
        }
        //console.log(res.body['MoreCategory'])
      }
    )

    //this.router.navigate(['/stream', categories])
    // console.log(categories)
  }

}
